.search-suggest-override p {
  margin: 0;
  cursor: pointer;
}

.search-history-box {
  border-top: 1px solid #707070;
}

.search-did-you-mean {
  margin-top: -28px;
}
/*tags on new tables fill the container, this stops that*/
.table-over-rides div[data-testid="tag"] {
  height: 20px;
}

.response-item > * {
  margin-bottom: 24px;
}

/* custom css to make notifications float*/
.message-floating {
  justify-content: center;
  position: fixed;
  top: 24px;
  width: 100%;
  z-index: 1000;
}
.message-floating > div {
  width: fit-content;
}

.feedback-control {
  display: flex;
  align-items: center;
  overflow: visible;
  margin-top: 24px;
  margin-bottom: 8px;
}
.feedback-control > :nth-child(1) {
  margin-right: 18px;
}

.feedback-control > :nth-child(2) {
  margin-right: 8px;
}

.feedback-control__title {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #000;
}

.ai-feedback-modal {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 0px;
}

.ai-feedback-modal * {
}

/* TODO: NO idea what this is for but it overrides all buttons
/* in all section modes (light|dark) turning off but what was it 'fixing'?
/*.button__root--type_primary {*/
/*  background-color: #228181;*/
/*}
*/

.typography--size_heading-huge {
  font-size: 5.6rem;
  line-height: 6.4rem;
}
